<template>
  <!-- 轮播图 -->
    <Banner :imgArr="state.bannerArr" v-if="state.bannerArr!=''" />
  <div class="section">
    <!-- <h2>{{state.curName}}</h2> -->
    <!-- <div class="time"><span style="margin-right:.5rem">2022-03-26</span><span>浏览量：7589</span></div> -->
    <Section :section="state.section" :topIsShow="state.topIsShow" v-if="state.section.leftNav != ''">  
      <!-- 农业大数据 -->
      <template v-slot:1 >
        <p class="animate__animated animate__fadeInUp wow">{{state.info[0].describe}}</p>
        <div class="imgBox animate__animated animate__fadeInUp wow">
            <img :src="item.images" v-for="(item,index) in state.info[0].imgs" :key="index" />
        </div>
      </template>
      <!-- 浓情预警指挥中心 -->
      <template v-slot:2>
        <div  class="style1">
          <p class="animate__animated animate__bounceInLeft wow">>{{state.info[1].describe}}</p>
          <img :src="state.info[1].imgs[0].images"  class="animate__animated animate__bounceInRight wow" />
        </div>
        <h5 class=" animate__animated animate__fadeInUp wow">{{state.info[1].jump_url}}</h5>
        <img :src="state.info[1].imgs[1].images" class=" animate__animated animate__fadeInUp wow" />
        <img :src="state.info[1].imgs[2].images" class=" animate__animated animate__fadeInUp wow" />
      </template>
      <!-- 农产品质量安全网格化管理系统 -->
      <template v-slot:3>
        <h5 class=" animate__animated animate__bounceInLeft wow">{{state.info[2].jump_url}}</h5>
        <p class=" animate__animated animate__fadeInUp wow">{{state.info[2].describe}}</p>
        <h5 class=" animate__animated animate__bounceInLeft wow">{{state.info[2].jump_url1}}</h5>
        <img :src="item.images" v-for="(item,index) in state.info[2].imgs" :key="index"  class="animate__animated animate__fadeInUp wow"/>
      </template>
      <!-- 农资监管系统 -->
      <template v-slot:4>
          <p class="animate__animated animate__fadeInUp wow">{{state.info[3].describe}}</p>
          <img :src="state.info[3].imgs[0].images" class="animate__animated  animate__bounceInUp  wow" />
          <h5 class=" animate__animated animate__bounceInDown wow">{{state.info[3].jump_url}}</h5>
          <div class="style2">
            <p class=" animate__animated animate__bounceInLeft wow">{{state.info[3].describe1}}</p>
            <p class=" animate__animated animate__bounceInRight wow">{{state.info[3].describe2}}</p>
          </div>
          <img :src="state.info[3].imgs[1].images" class="animate__animated animate__fadeInUp wow" />
          <p class="animate__animated animate__fadeInUp wow">{{state.info[3].jump_url1}}</p>
      </template>
    </Section>
  </div>
  <!-- 返回顶部 -->
  <img src="@/assets/images/top.png" class="top" v-if="state.topIsShow" @click="backTop" />

  <img src="@/assets/images/logoBg.png" class="logoSize logoBgLeft logoBg1" />
  <img src="@/assets/images/logoBg.png" class="logoSize logoBgRight logoBg2" />
  <img src="@/assets/images/logoBg.png" class="logoSize logoBgLeft logoBg3" />
  <img src="@/assets/images/logoBg.png" class="logoSize logoBgRight logoBg4" />
  <img src="@/assets/images/logoBg.png" class="logoSize logoBgLeft logoBg5" />
  <img src="@/assets/images/logoBg.png" class="logoSize logoBgRight logoBg6" />
</template>

<script>
import { useRoute } from 'vue-router'
import {onMounted,watchEffect,reactive} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
import Banner from "@/components/banner1.vue"
// import { getYewDetail} from "../../api/request"
import Section from "./components/sectionNav.vue"
import { getYewDetail,getbannerData} from "../../api/request"
export default {
  name: "Business",
  components:{
    Banner,
    Section
  },
  setup() {
    const route = useRoute()
    onMounted(()=>{
        var wow = new WOW({
        boxClass: 'wow',    //需要执行动画元素的Class
        animateClass: 'animated',    //animation.css动画的Class
        offset: 100,    //距离可视区域多少开始执行动画
        mobile: true,    //是否在移动设备执行动画
        live: true    //异步加载的内容是否有效
        })
        wow.init()
        getBanner()
        window.scrollTo(0,0);
      })
        
    let state = reactive({
      bannerArr:[],
      curId:0,
      curName:"",
      info:"",
      section:{
        title:"",
        leftNav:[
        ],
        isShow:false
      },
      scrollHeight:0,
      topIsShow:false 
    })
    watchEffect(()=>{
        state.curId = route.query.id
        getData(state.curId)
        // window.scrollTo(0,0);
         window.addEventListener('scroll',scrollY,true)
        // scrollY()
        
    })
    function getBanner(){
      getbannerData({id:37}).then((res)=>{
        // console.log(res)
        if(res.code == 200){
          state.bannerArr = res.data
        }
      })
    }
    function getData(id){
      // getYewNav().then((res) => {
      //   // let data = res.data
      //   console.log(res)
      // })
      getYewDetail({id:id}).then((res)=>{
        // console.log(id)
        // console.log(res)
        state.section.leftNav = res.data.title
        state.section.title = res.data.top_t.name
        state.info =  res.data
        state.section.isShow = true
        // console.log(state.info)
      })
    }
    // 滚动条距离顶部距离
    function scrollY(){
      state.scrollHeight = window.pageYOffset
      if(state.scrollHeight > 500){
        state.topIsShow = true
      }else{
        state.topIsShow = false
      }
    }
    function backTop(){
        // window.scrollTo(0,0);
      let timer = setInterval(() => {
        let ispeed = Math.floor(-state.scrollHeight / 5)
        document.documentElement.scrollTop = document.body.scrollTop = state.scrollHeight + ispeed
        if (state.scrollHeight === 0) {
          clearInterval(timer)
        }
      }, 16)
    }
    return {
      state,
      getData,
      getBanner,
      scrollY,
      backTop
    };
  },
};
</script>

<style lang="scss" scoped>
  .section{
    padding-bottom: 1rem;
    p{
      font-size: .16rem;
      color: #555;
      line-height: .3rem;
      text-indent: .3rem;
    }
    img{
      margin: .5rem auto ;
      max-width: 100%;
      display: block;
    }
    h5{
      font-size: .18rem;
      color: #555;
      line-height: 1rem;
      text-align: center;
    }
    .imgBox{
      display: flex;
      margin: .2rem 0 0;
      img{
        margin: .1rem .05rem;
      }
    }
    .style1{
      display: flex;
      p{
        flex: 1;
        margin: .5rem .2rem 0 0;
      }
      img{
        flex: 1;
      }
    }
    .style2{
      display: flex;
      p{
        flex: 1;
        background: #DBEBC6;
        border-radius: .2rem;
        margin: 0 .1rem;
        padding: .15rem .2rem;
      }
    }
  }
  .top{
    position: fixed;
    right: 0;
    bottom: 1rem;
    width: .5rem;
    height: .5rem;
    cursor: pointer;
    z-index: 9999;
  }
  .logoSize{
    width: 1.065rem;
    height: 1.81rem;
  }
  .logoBgLeft{
    position: absolute;
    left: 0;
  }
  .logoBgRight{
    transform:rotate(180deg);
    position: absolute;
    right: 0;
  }
  .logoBg1{
    top: 10rem;
  }
  .logoBg2{
    top: 20rem;
  }
  .logoBg3{
    top: 30rem;
  }
  .logoBg4{
    top: 40rem;
  }
  .logoBg5{
    top: 50rem;
  }
</style>